
import {reactive, toRefs, onMounted, defineComponent} from 'vue';

import FormAllowedDomain from '@/components/admin/allowedDomains/FormAllowedDomain.vue';
import AllowedDomainItem from '@/components/admin/allowedDomains/AllowedDomainItem.vue';
import identity from '@/api/identity';

import {toast} from 'bulma-toast';
import {AxiosResponse} from 'axios';
import {AllowedDomains} from '@/types/Application';

interface Data {
    loading: boolean;
    domains: AllowedDomains[];
    allowed: boolean;
    error: boolean;
    loadingCreation: boolean;
}

export default defineComponent({
    name: 'AllowedDomains',
    components: {FormAllowedDomain, AllowedDomainItem},
    setup ()
    {
        const data: Data = reactive({
            loading: true,
            domains: [],
            allowed: true,
            error: false,
            loadingCreation: false,
        });

        async function fetchAllowedDomains ()
        {
            try
            {
                const response: AxiosResponse = await identity.adminGetAllowedDomains();
                data.domains = [...response.data];
            }
            catch (e)
            {
                if (e.response && (e.response.status === 403 || e.response.status === 401)) data.allowed = false;
                else showErrorAlert('Error loading domains');
            }
            data.loading = false;
        }

        async function createDomainItem (domain: string)
        {
            data.loadingCreation = true;
            try
            {
                const response: AxiosResponse = await identity.adminCreateAllowedDomains({name: domain});
                if (response.status == 200)
                {
                    const {id, name} = response.data;
                    data.domains.push({id, name});
                    sortDomainArray();
                    showSuccessAlert('Domain created successfully!');
                }
            }
            catch (e)
            {
                if (e.response && e.response.status === 403) data.allowed = false;
                if (e.response && e.response.status === 409) showErrorAlert('Domain already exists');
                else showErrorAlert('Error trying to create domain');
            }
            data.loadingCreation = false;
        }

        async function editDomainItemFromArray (response: number)
        {
            if (response === 200) return showSuccessAlert('The domain was updated successfully!');
            if (response === 403) return data.allowed = false;
            if (response === 409) return showErrorAlert('Looks like there is already an existing domain');
            showErrorAlert('Something went wrong, we could not update the domain');
        }

        async function deleteDomainItemFromArray ({id, response}: { id: string, response: number })
        {
            if (response === 204)
            {
                data.domains = data.domains.filter(domain => domain.id != id);
                showSuccessAlert('The domain was successfully deleted!');
                return;
            }
            if (response === 403) return data.allowed = false;
            showErrorAlert('Something went wrong, we could not delete the domain');
        }

        function showSuccessAlert (message = 'Everything is fine, action done')
        {
            toast({
                message: message,
                type: 'is-success',
                duration: 3000,
                dismissible: true,
                pauseOnHover: true,
                position: 'bottom-right'
            });
        }

        function showErrorAlert (message = 'There was an error')
        {
            toast(
                {
                    message: message,
                    type: 'is-danger',
                    duration: 5000,
                    dismissible: true,
                    pauseOnHover: true,
                    position: 'bottom-right'
                });
        }

        function sortDomainArray ()
        {
            data.domains.sort(function (a, b)
            {
                if (a.name > b.name)
                {
                    return 1;
                }
                if (a.name < b.name)
                {
                    return -1;
                }
                return 0;
            });
        }

        onMounted(() =>
        {
            fetchAllowedDomains();
        });
        return {...toRefs(data), createDomainItem, editDomainItemFromArray, deleteDomainItemFromArray};
    }
});
