
import {reactive, toRefs, defineComponent} from 'vue';

import {validateDomain} from './validation';

export default defineComponent({
    name : 'FormAllowedDomain',
    emits: ['pushItem'],
    props : {
        loading:{
            type: Boolean,
            required: true
        }
    },
    setup (props, {emit})
    {
        const data = reactive({
            domain : '',
            errors : '',
        });

        function handleSubmit (e: Event)
        {
            e.preventDefault();

            const validateDomainResponse = validateDomain(data.domain);
            if(typeof validateDomainResponse === 'string')
            {
                data.errors = validateDomainResponse;
                return;
            }

            emit('pushItem', data.domain);
            data.domain = '';
            data.errors = '';
        }

        return {...toRefs(data), handleSubmit};
    }
});
