function validateDomain (domain: string) : string | boolean
{
    if(domain === '') return 'Field should not be empty';

    const expUrl = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/gm.test(domain);
    if (!expUrl)  return 'This field must be a valid domain';
    return true;

}

export {validateDomain};
