
import {defineComponent, reactive, toRefs, onBeforeMount} from 'vue';

import {validateDomain} from './validation';
import identity from '@/api/identity';

import {toast} from 'bulma-toast';
import {AxiosResponse} from 'axios';

interface Data {
    domainComponent: any;
    edit: boolean;
    loadingAction: boolean,
}

export default defineComponent({
    name: 'AllowedDomainItem',
    props: {
        domain: {
            required: true,
            type: Object,
        }
    },
    setup (props, {emit})
    {
        const data: Data = reactive({
            domainComponent: {},
            edit: false,
            loadingAction: false
        });

        async function submitEditDomainHandler (e: Event): Promise<void>
        {
            e.preventDefault();
            data.loadingAction = true;
            const validateDomainResponse = validateDomain(data.domainComponent.name);
            if (typeof validateDomainResponse === 'string')
            {
                showErrorAlert(validateDomainResponse);
                data.loadingAction = false;
                return;
            }
            const response = await requestForUpdate();
            if (response == 200) data.edit = false;
            if (response == 409) cancelEditHandler(true);
            data.loadingAction = false;
            emit('editItem', response);
        }

        async function requestForUpdate ()
        {
            try
            {
                const response: AxiosResponse = await identity.adminUpdateAllowedDomains(
                    data.domainComponent.id, {name: data.domainComponent.name});
                if (response.status === 200) return 200;
            }
            catch (e)
            {
                if (e.response && e.response.status === 403) return 403;
                if (e.response && e.response.status === 409) return 409;
                else return e.response.status;
            }
        }

        function cancelEditHandler (edit = false)
        {
            data.edit = edit;
            data.domainComponent = {...props.domain};
        }

        async function deleteDomainHandler ()
        {
            data.loadingAction = true;
            try
            {
                const response: any = await identity.adminDeleteAllowedDomains(data.domainComponent.id);
                if (response.status == 204) emit('deleteItem', {id: data.domainComponent.id, response: 204});
            }
            catch (e)
            {
                if (e.response && e.response.status === 403) emit('deleteItem', {
                    id: data.domainComponent.id,
                    response: 403
                });
                else emit('deleteItem', {id: data.domainComponent.id, response: 500});
            }
            data.loadingAction = false;
        }

        function showErrorAlert (error = 'Looks like something went wrong')
        {
            toast({
                message: error,
                type: 'is-danger',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
                position: 'center'
            });
        }

        onBeforeMount(() =>
        {
            data.domainComponent = {...props.domain};
        });
        return ({...toRefs(data), submitEditDomainHandler, cancelEditHandler, deleteDomainHandler});
    }
});
